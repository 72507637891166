<template>
  <a-modal
    v-model="show"
    title="服务日志"
    :footer="null"
    :width="1000"
    @cancel="handleCancleClick"
  >
    <a-button @click="toImage">导出图片</a-button>

    <div id="imageWrapper" ref="imageWrapper">
      <div style="text-align:center;font-size:20px;font-weight:bold;margin-top:20px">
        服务日志
      </div>
      <div style="margin-left:20px"><img src="eaton-icon.png" width="100" /></div>
      <a-card title="工单信息" :bordered="false">
        <a-row :gutter="8">
          <a-col :span="12"> 项目名称： </a-col>
          <a-col :span="12">
            {{ taskData.projectName }}
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="12"> 合同号： </a-col>
          <a-col :span="12">
            {{ taskData.contractNo }}
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="12"> 产品型号： </a-col>
          <a-col :span="12">
            {{ taskData.project != undefined ? taskData.project.productVersion : '' }}
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="12"> 工单号： </a-col>
          <a-col :span="12">
            {{ taskData.taskNo }}
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="12"> 工单类型： </a-col>
          <a-col :span="12">
            {{ taskType.length != 0 && taskType.find((v) => v.keyy == taskData.taskType) != undefined ? taskType.find((v) => v.keyy == taskData.taskType).valuee : '' }}
          </a-col>
        </a-row>
        <a-row :gutter="8">
          <a-col :span="12"> 工程师： </a-col>
          <a-col :span="12">
            {{ taskData.taskAssigns != undefined ? taskData.taskAssigns.map((v) => {return v.engineer.username}).join(',') : '' }}
          </a-col>
        </a-row>
      </a-card>

      <a-card title="服务日志" :bordered="false">
        <a-table
          :columns="columns"
          :data-source="logData"
          :defaultExpandAllRows="true"
          :rowKey="(record) => record.logDate"
          :pagination="false"
        >
          <template slot="jobTypeList" slot-scope="text">
            <a-badge
              v-for="(item, index) of text.split('')"
              :color="taskType.find((v) => v.keyy == item).otherKeyy"
              :text="taskType.find((v) => v.keyy == item).valuee"
              :key="index"
            >
            </a-badge>
          </template>
        </a-table>
      </a-card>

      <a-card title="服务反馈" :bordered="false">
        <a-row :gutter="16" v-if="taskData.taskReceipts!=undefined">
          <a-col
            :span="8"
            v-for="(item, index) of taskData.taskReceipts.filter(item=>item.type == 1)"
            :key="index"
          >
            <a-card :bordered="false">
              <img
                slot="cover"
                height="300"
                :src="
                  $store.state.baseinfo.filedomain +
                  item.receiptPic +
                  '?' +
                  randomString(6)
                "
                crossorigin="anonymous"
              />
            </a-card>
          </a-col>
        </a-row>
      </a-card>

      <a-card title="客户签字" :bordered="false">
        <a-row :gutter="16" v-if="taskData.taskReceipts!=undefined">
          <a-col
            :span="8"
            v-for="(item, index) of taskData.taskReceipts.filter(item=>item.type == 2)"
            :key="index"
          >
            <a-card :bordered="false">
              <img
                slot="cover"
                height="300"
                :src="
                  $store.state.baseinfo.filedomain +
                  item.receiptPic +
                  '?' +
                  randomString(6)
                "
                crossorigin="anonymous"
              />
            </a-card>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </a-modal>
</template>
<script>
import moment from "moment";
import html2canvas from "html2canvas";

export default {
  name: "taskLog",
  props: {
    logVisiable: {
      require: true,
      default: false,
    },
    taskType: {
      type: Array,
      required: true,
      default: () => [],
    },
    taskData: {
      require: true,
    },
  },
  computed: {
    show: {
      get: function () {
        return this.logVisiable;
      },
      set: function () {},
    },
    columns() {
      return [
        {
          title: "日期",
          dataIndex: "logDate",
          width: 150,
        },
        {
          title: "柜号",
          dataIndex: "sn",
          width: 150,
        },
        {
          title: "日志内容",
          width: 300,
          dataIndex: "logContent",
        },
        {
          title: "作业类别",
          dataIndex: "jobType",
          width: 200,
          scopedSlots: { customRender: "jobTypeList" },
        },
      ];
    },
    logData() {
      const data = [];
      for (const logDate in this.taskData.taskLogs) {
        const children = [];
        for (const item of this.taskData.taskLogs[logDate]) {
          children.push({
            logDate: moment(item.createTime).format("HH:mm:ss"),
            sn: item.sn,
            logContent: item.logContent,
            jobType: this.taskData.taskType,
          });
        }
        data.push({
          logDate: moment(logDate).format("YYYY-MM-DD"),
          sn: "",
          logContent: "",
          jobType: "",
          children: children,
        });
      }
      return data;
    },
  },
  data() {
    return {};
  },
  methods: {
    toImage() {
      html2canvas(this.$refs.imageWrapper, {
        allowTaint: false,
        useCORS: true,
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        console.log(dataURL, "dataURL");
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = "task_log_" + this.taskData.taskId;;
        a.href = dataURL;
        a.dispatchEvent(event);
      });
    },
    handleCancleClick() {
      this.$emit("close");
    },
    randomString(len, charSet) {
      charSet =
        charSet ||
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var randomString = "";
      for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      return randomString;
    },
  },
};
</script>
